import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import { H1, P, ButtonLink } from '../components';

export default function NotFound() {
  return (
    <Layout title="Not Found" noIndex>
      <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60 flex flex-col gap-10 justify-center items-center text-center m-auto">
        <H1>Page not found</H1>
        <P>We are very sorry. This page does not exist</P>
        <ButtonLink to="/">To Homepage</ButtonLink>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
